:root {
  --primaryColor: #ee4c08;
  --textColor: white;
  --primaryFont: "Rubik", sans-serif;
}

* {
  margin: 0;
  outline: none;
  padding: 0;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}
p {
  color: #656565;
}
html {
  scroll-behavior: smooth;
}
.color-primary {
  color: var(--primaryColor);
}

/* Header  */
.navbar-main {
  background: #efefef;
  border-bottom: 1px solid #403d3d;
}
.navbar-main .contact-btn {
  background-color: white;
  padding: 10px 20px;
  text-transform: uppercase;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  font-size: 14px;
  border-radius: 20px;
  font-weight: 500;
}
.navbar-main .contact-btn:hover {
  background-color: var(--primaryColor);
  color: white;
}

.navbar-main .navbar li a.nav-link {
  color: #222222;
  height: 40px;
}
.navbar-main .navbar li a.nav-link:hover {
  border-bottom: 2px solid var(--primaryColor);
}
/* Homepage  */
/* Header */
.homepage-header {
  background-image: url("./assets/header-bg.webp");
  background-size: cover;
  background-position: center bottom;
}

.homepage-main .header-title {
  color: white;
  padding-top: 7rem;
}
.homepage-main .header-title h2 {
  font-size: 55px;
  font-weight: 700;
}
.homepage-main .header-title h3 {
  font-size: 55px;
  font-weight: 500;
}
.homepage-main .header-title p {
  font-size: 18px;
}
.features-section {
  background-image: url("./assets/feature__after.png");
  background-size: cover;
  background-position: center;
}
.features-section .title p {
  padding-inline: 25%;
}
.footer-main {
  background-color: #1a1a1a;
}
.footer-main .container-xl {
  border-bottom: 1px solid var(--secondaryColor);
  padding-bottom: 15px;
}
.footer-lower {
  background-color: #1a1a1a;
  font-size: 14px;
}
.blog-header {
  background: url(./assets/Blogs/blog-title-bg.png);
}
.blog-post .desc {
  color: #6c6c72;
}
.read-more {
  background-color: #faf1b3;
  color: #eb2030;
  padding: 5px 15px;
  border-radius: 15px;
  font-weight: 500;
  text-decoration: none;
}
.recent-blogs .first-blog img {
  height: 230px;
  object-fit: cover;
}
.brands .brand-box .brand-icon img {
  width: 60px;
}
.pakistani-brands {
  background-color: #f9f8f8;
}
.figures-section .figures {
  position: relative;
  padding: 20px;
}
.figures-section .figures h1 {
  padding-left: 15px;
  font-weight: 700;
  background-image: linear-gradient(to right, #8e4800, #ee4c08);
  -webkit-background-clip: text; /* For WebKit browsers */
  background-clip: text;
  color: transparent;
}
.figures-section .figures::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  top: 40px;
  width: 1px;
  height: 60px;
  background-color: #bfbbbb;
}
/* Responsive view  */
@media (max-width: 1400px) {
  .homepage-main .header-title {
    padding-top: 5rem;
  }
  .features .features-main-img img {
    left: 30px;
  }
}
@media (max-width: 1200px) {
  .homepage-main .header-title h2,
  .forcast-heading h2 {
    font-weight: 600;
  }
  .homepage-main .header-title {
    padding-top: 2rem;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .homepage-header {
    height: 70rem;
  }
  .homepage-main .header-title h2 {
    font-size: 42px;
  }
  .homepage-main .header-title h3 {
    font-size: 32px;
  }
  .homepage-main .header-title {
    justify-content: center;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .features-section .title p {
    padding-inline: 0px;
  }
  .homepage-main .header-title h2 {
    font-size: 28px;
  }
  .homepage-main .header-title h3 {
    font-size: 24px;
  }
  .homepage-main .header-title {
    justify-content: center;
    text-align: center;
  }
  .navbar-main .contact-btn {
    font-size: 12px;
    padding: 8px 15px;
  }

  .navbar-main .navbar li a.nav-link {
    color: black;
  }
}
@media (max-width: 425px) {
  .navbar-main .navbar-brand img {
    height: 20px;
  }
  .navbar-main .contact-btn {
    font-size: 10px;
    padding: 8px 10px;
  }
}
